<template>
  <div class="wrapper">
    <common-header></common-header>
    <common-sidebar></common-sidebar>
    <div class="content-box" :class="{ 'content-collapse': collapse }">
      <common-tags></common-tags>
      <div class="content">
        <keep-alive>
          <transition name="fade-transform" class="fade-transform" mode="out-in">
            <router-view v-if="$route.meta.keepAlive"></router-view>
          </transition>
        </keep-alive>
        <transition name="fade-transform" class="fade-transform" mode="out-in">
          <router-view v-if="!$route.meta.keepAlive"></router-view>
        </transition>
        <el-backtop target=".content" class="myBacktop"></el-backtop>
      </div>
    </div>
  </div>
</template>

<script>
import commonHeader from "./common/commonHeader";
import commonSidebar from "./common/commonSidebar";
import commonTags from "./common/commonTags";
import bus from "../service/bus";

export default {
  components: {
    commonHeader,
    commonSidebar,
    commonTags,
  },
  data() {
    return {
      collapse: false,
    };
  },
  created() {
    //-console.log(this.keepAlive)
    // 控制折叠面板
    bus.$on("collapse-content", (msg) => {
      this.collapse = msg;
    });
  },
};
</script>
<style lang="scss">
.myBacktop {
  color:#fff;
  background-color: #51CDCB;
}
.myBacktop:hover {
  color:#51CDCB;
  background-color: #fff;
}
</style>
<style scoped>
/* fade-transform */
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all .5s;
}

.fade-transform-enter {
  opacity: 0;
  transform: translateX(-30px);
}

.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
