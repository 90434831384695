<template>
  <div class="sidebar yqh">
    <el-menu :default-active="onRoute" class="sidebar-el-menu" :collapse="collapse" :background-color="subjectColor"
      text-color="#fff" active-text-color="#ffd04b" router unique-opened>
      <template v-for="item in items">
        <template v-if="!item.hideen">
          <template v-if="item.subs">
            <el-submenu :index="item.index" :disabled="item.disabled" :key="'a' + item.index" v-if="item.member">
              <template slot="title">
                <!-- 预留字体图标 -->
                <svg class="menuIcon" v-if="item.icon.indexOf('#') != -1" aria-hidden="true">
                  <use :xlink:href="item.icon"></use>
                </svg>
                <div class="elIcon" v-if="item.icon.indexOf('el-') != -1" :class="item.icon"></div>
                <span slot="title" class="title">{{ item.title }} </span>
              </template>
              <template v-for="(subItem, index) in item.subs">
                <template v-if="subItem.root">
                  <el-submenu v-if="subItem.subs && isMain && !subItem.hideenSubs" :index="subItem.index"
                    :disabled="subItem.disabled" :key="'b' + subItem.id" class="title">
                    <template slot="title" class="title">{{
      subItem.title
    }}</template>
                    <el-menu-item v-for="(threeItem, i) in subItem.subs" :key="'c' + i" :index="threeItem.index"
                      :disabled="threeItem.disabled" class="title">{{ threeItem.title }}</el-menu-item>
                  </el-submenu>
                  <el-menu-item v-else-if="isMain || subItem.administrator" :index="subItem.index"
                    :disabled="subItem.disabled" :key="'d' + subItem.id" class="title pl50">{{ subItem.title }}
                  </el-menu-item>
                </template>
                <template v-else>
                  <el-submenu v-if="subItem.subs && subItem.memberRoot && !subItem.hideenSubs" :index="subItem.index"
                    :disabled="subItem.disabled" :key="'e' + subItem.id" class="title pl10">
                    <template slot="title" class="title">{{
      subItem.title
    }}</template>
                    <el-menu-item v-for="(threeItem, i) in subItem.subs" :key="'f' + i" :index="threeItem.index"
                      :disabled="threeItem.disabled" class="title">{{ threeItem.title }} </el-menu-item>
                  </el-submenu>
                  <el-menu-item v-else :index="subItem.index" :disabled="subItem.disabled" :key="'g' + index"
                    class="title pl50" :class="!subItem.memberRoot ? 'memberRoot' : ''">{{ subItem.title }}
                  </el-menu-item>
                </template>
              </template>
            </el-submenu>
          </template>
          <template v-else>
            <el-menu-item :index="item.index" :disabled="item.disabled" :key="'h' + item.index" class="title"
              v-if="!info.hidden && item.member && !item.isAgent">
              <!-- 预留字体图标 -->
              <!-- <svg class="menuIcon" aria-hidden="true" style="margin-right: 7px; font-size: 18px">
              <use :xlink:href="item.icon"></use>
            </svg> -->
              <svg class="menuIcon" v-if="item.icon.indexOf('#') != -1" aria-hidden="true">
                <use :xlink:href="item.icon"></use>
              </svg>
              <div class="elIcon" v-if="item.icon.indexOf('el-') != -1" :class="item.icon"></div>
              <span slot="title" class="title">{{ item.title }}</span>
            </el-menu-item>
            <el-menu-item :index="item.index" :disabled="item.disabled" :key="'i' + item.index" class="title"
              v-else-if="!info.hidden && info.isAgent == 1 && info.roleId">
              <!-- 代理企业 -->
              <!-- <svg class="menuIcon" aria-hidden="true">
                <use :xlink:href="item.icon"></use>
              </svg> -->
              <svg class="menuIcon" v-if="item.icon.indexOf('#') != -1" aria-hidden="true">
                  <use :xlink:href="item.icon"></use>
                </svg>
                <div class="elIcon" v-if="item.icon.indexOf('el-') != -1" :class="item.icon"></div>
              <span slot="title" class="title">{{ item.title }}</span>
            </el-menu-item>
          </template>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>
import bus from "../../service/bus";
import { mapState } from "vuex";
import { queryEmpRouter } from '@/api/companyManage'
import items from '@/utils/root'
export default {
  name: "commonSidebar",
  data() {
    return {
      collapse: false,
      isMain: false,
      member: false,
      info: this.$store.state.loginRoot.userInfo,
      items: [],
      menuType: null,
      routers: [],
    };
  },
  /**
   * 判断用户菜单权限 根据权限显示菜单
   * 判断是否开启分销会员 显示隐藏分销会员
   * 判断是否为该公司创建人 开启全部菜单
  */
  created() {
    let userInfo = JSON.parse(localStorage.getItem('info'));
    if (userInfo && userInfo.roleId === 0) {
      queryEmpRouter({ a: '' })
        .then(res => {
          if (res.data) {
            let arr = JSON.parse(res.data.routerId)
            this.$store.commit('setRouterArr', arr)
            this.$store.commit('setEmpId', res.data.empId)
          } else {
            let arr = ['033', '05', '050', '051', '16'] //成员登录时默认开启几个权限
            this.$store.commit('setRouterArr', arr)
          }

        })
    }
    // 控制折叠面板
    bus.$on("collapse", (msg) => {
      this.collapse = msg;
      bus.$emit("collapse-content", msg);
    });

  },

  computed: {
    ...mapState(["routerArr"]),
    // 主题颜色
    subjectColor() {
      return this.$store.state.subjectColor;
    },
    getMenuType() {
      return this.$store.state.menuType;
    },
    // 路由配置
    onRoute() {
      return this.$route.path.replace("/", "");
    },
    jurisdiction() {
      let arr = [
        "miniProgram",
        "sharingPromotion",
        "marketingActivities",
        "financialManagement",
        "articleManagement",
        "orderManagement",
      ];
      return arr;
    },
    memberJurisdiction() {
      let arr = [
        "totalCustomer",
        "corporateInformation",
        "advertisingManagement",
      ];
      return arr;
    },
    getMyParkData() {
      return this.$store.state.myParkData;
    },
    getMyAssociationData() {
      return this.$store.state.myAssociationData;
    },
  },
  watch: {
    /**@method 监听权限显示菜单*/
    routerArr: {
      handler(routers) {
        this.routers = routers;
       //console.log(this.routerArr);
        this.menuType = sessionStorage.getItem("menuType");
        // this.setItmes();
        if (this.menuType === 'card') {
          this.cardMenu();
        } else if (this.menuType === 'yuanqv') {
          this.yuangqiMenu();
        } else if (this.menuType === 'shetuan') {
          this.shetuanMenu();
        }
      },
      deep: true,
      immediate: true
    },
    /**监听变换的类型 */
    getMenuType: {
      handler(val) {
        //-console.log("切换");
        this.menuType = val;
        if (this.menuType === 'card') {
          this.cardMenu();
        } else if (this.menuType === 'yuanqv') {
          this.yuangqiMenu();
        } else if (this.menuType === 'shetuan') {
          this.shetuanMenu();
        }
      },
      deep: true,
      immediate: true
    },
    /**监听园区数据 */
    getMyParkData() {//用于判断园区是否为空
      if (this.menuType === 'yuanqv') {
        this.yuangqiMenu();
      }
    },
    /**监听社团数据 */
    getMyAssociationData() {//用于判断社团是否为空
      if (this.menuType === 'shetuan') {
        this.shetuanMenu();
      }
    }
  },
  methods: {
    /**@method 验证是否有权限 */
    setItmes() {
      let showAssociationMember = sessionStorage.getItem("showAssociationMember") === 'true' ? true : false;//是否显示会员管理
      let showParkMember = sessionStorage.getItem("showParkMember") === 'true' ? true : false;//是否显示会员管理
      let routers = this.routers;
      try {
        let than = this;
        this.items.map((item) => {
          if (item.id == "28") {//预约管理用在小程序端，所以不用显示
            item.member = false;
          } else if (routers.includes(item.id)) {
            item.member = true;
          } else if (item.id == "02" || item.id == "01") {//强制开放菜单 工作台 客户管理
            item.member = true;
          } else {
            item.member = false;
          }
          if (item.id == "27") {
            item.member = showParkMember;
          }
          if (item.id == "34") {
            item.member = showAssociationMember;
          }
          if (item.subs) {
            item.subs.map((subItem) => {
              if (subItem.id) {
                //子集
                if (routers.includes(subItem.id)) {
                  if (subItem.id == "033") {
                    than.items[2].member = true;
                  }
                  subItem.memberRoot = true;
                  // if (subItem.administrator == false) {
                  //   subItem.root = false;
                  //   subItem.administrator = true;
                  // }
                } else if ((["020", "021", "022", "023", "024"]).includes(subItem.id)) {//开启客户管理下的全部功能
                  subItem.memberRoot = true;
                } else {
                  subItem.memberRoot = false;
                }
              }
            });
          }

        });
      } catch (error) {
        //-console.log(error);
      }
    },
    /**@method 隐藏/显示菜单 */
    hiddenCRMItem() {
      this.info = JSON.parse(localStorage.getItem("info"));
      this.isMain = this.info.main;
      let than = this;
      this.items.map((item, index) => {
        if (this.jurisdiction.includes(item.index)) {
          item.member = this.info.roleId ? true : false;
        }
        if (item.index == "sharingPromotion" || item.index == "addedServices") {
          item.member = this.isMain;
        }
        if (item.subs) {
          item.subs.map((subItem) => {
            if (than.memberJurisdiction.includes(subItem.index)) {
              subItem.memberRoot = than.info.roleId ? true : false;
            }
            if (than.info.roleId) {
              subItem.memberRoot = true;
            }
          });
        }
        if (than.info.roleId) {
          item.member = true;
        }
      });
    },
    /**@method crm菜单数据 */
    cardMenu() {
      this.items = items.crmItems;
      this.hiddenCRMItem();
      if (!this.info.roleId) {
        this.setItmes();
      }
    },
    /**@method 园区菜单数据 */
    async yuangqiMenu() {
      /**有园区显示完整菜单 */
      let itemsData = [];
      let showParkMember = sessionStorage.getItem("showParkMember") === 'true' ? true : false;//是否显示会员管理
      for (let row of items.parkItems) {
        if(!"disabled" in row){
          if (!this.$store.state.myParkData || this.$store.state.myParkData.length === 0) {
          if (row.id !== '20') {
            row.disabled = true;
          }
          if (row.subs) {
            for (let child of row.subs) {
              if (child.id !== '200') {
                child.disabled = true;
              }
            }
          }
        } else {
         //console.log(row.title);
         //console.log(row.disabled);
          row.disabled = false;
          if (row.subs) {
            for (let child of row.subs) {
              child.disabled = false;

            }
          }
          if (row.id === '27') {
            row.member = showParkMember;
          }
        }
        }
        itemsData.push(row);
      }
      this.items = itemsData;
      if (!this.info.roleId && this.$store.state.myParkData && this.$store.state.myParkData.length > 0) {
        this.setItmes();
      }
    },
    /**@method 社团菜单数据 */
    shetuanMenu() {
      /**有园区显示完整菜单 */
      let itemsData = [];
      let showAssociationMember = sessionStorage.getItem("showAssociationMember") === 'true' ? true : false;//是否显示会员管理
      for (let row of items.associationItems) {
        //判断是否有社团数据
        if (!this.$store.state.myAssociationData || this.$store.state.myAssociationData.length === 0) {
          if (row.id !== '45') {
            row.disabled = true;
          }
          if (row.subs) {
            for (let child of row.subs) {
              if (child.id !== '290') {
                child.disabled = true;
              }
            }
          }
        } else {
          row.disabled = false;
          if (row.subs) {
            for (let child of row.subs) {
              child.disabled = false;
            }
          }
          if (row.id === '34') {//判断是否开启分销
            row.member = showAssociationMember;
          }
        }
        itemsData.push(row);
      }
      // if (!this.$store.state.myAssociationData || this.$store.state.myAssociationData.length === 0) {
      //   itemsData.push({
      //     icon: "el-icon-office-building",
      //     index: "association",
      //     title: "社团管理",
      //     member: true,
      //     type: "association",
      //     id: "29",
      //   })
      // } else {
      //   for (let row of items.associationItems) {
      //     if (row.id == "34") {
      //       if (showMember && showMember === 'true') {
      //         row.member = true;
      //       } else {
      //         row.member = false;
      //       }
      //     }
      //     itemsData.push(row);
      //   }
      // }
      this.items = itemsData;
      if (!this.info.roleId && this.$store.state.myAssociationData && this.$store.state.myAssociationData.length > 0) {
        this.setItmes();
      }
    },
    filterRouter(rootId) {
      let arr = this.$store.state.routerArr;
      let info = JSON.parse(localStorage.getItem("info"));
      if (info.main || info.roleId) {
        return true;
      }
      // if()
      let result = arr.find((item) => {
        return item == rootId;
      });
      return result;
    },
  },
};
</script>

<style scoped>
.menuIcon {
  width: 18px;
  height: 18px;
  margin-right: 7px;
  vertical-align: middle;
  ;
  padding: 0;
}

.elIcon {
  margin-right: 7px;
  font-size: 18px;
}

.sidebar {
  display: block;
  position: absolute;
  width: 208px;
  left: 0;
  top: 50px;
  padding-top:18px;
  bottom: 0;
  overflow-y: scroll;
  background: #fff;
}

.sidebar::-webkit-scrollbar {
  display: none;
}

.sidebar-el-menu:not(.el-menu--collapse) {
  width: 208px;
}

.sidebar>ul {
  height: 100%;
}

.sidebar .el-menu {
  border-right: none;
}

.title {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* .el-menu-item {
  border-left: #fff solid 6px;
} */
/* 设置鼠标悬停时el-menu-item的样式 */
/* .el-menu-item:hover {
  border-left: #6190e8 solid 6px !important;
  background-color: #e2eff9 !important;
  color: #6190e8 !important;
} */

/* .el-menu-item:hover i {
  color: #6190e8;
} */

/* 设置选中el-menu-item时的样式 */
.el-menu-item,
.el-submenu {
  /* opacity: 0.8; */
}

.el-menu-item .is-active {
  opacity: 1;
}

.memberRoot {
  display: none;
}
</style>
