<template>
  <div class="tags" v-show="showTags">
    <div class="boundary" id="nav">
      <ul id="navContent">
        <li class="tags-li" v-for="(item, index) in tagsList" :key="index" :class="{ 'active': isActive(item.path) }"
          :style="{ background: `${isActive(item.path) ? subjectColor : ''}`, width: (item.title.length * 13) + 5 + 40 + 'px', minWidth: (item.title.length * 13) + 5 + 40 + 'px' }">
          <router-link :to="item.path" class="tags-li-title" :style="{ minWidth: (item.title.length * 13) + 5 + 'px' }">{{
    item.title }}</router-link>
          <span class="tags-li-icon" @click="closeTags(index)">
            <i class="el-icon-close"></i>
          </span>
        </li>
      </ul>
    </div>
    <div class="tags-close-box pt10">
      <el-dropdown @command="handleTags" style='margin-right:23px'>
        <el-button size="mini" style="border-radius: 2px !important;border: 1px solid #51CBCD;color: #51CBCD;">
          页面选项
          <i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu size="small" slot="dropdown">
          <el-dropdown-item command="other">关闭其他</el-dropdown-item>
          <el-dropdown-item command="all">关闭所有</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import bus from "../../service/bus";
export default {
  name: "commonTags",
  data() {
    return {
      tagsList: [],
      initHref: false,
      memberType: "",
    };
  },
  watch: {
    $route() {
      this.scrollInit();
    }
  },
  created() {
    this.setTags(this.$route);
  },
  mounted() {
    this.scrollInit();
  },
  methods: {
    // 初始化与绑定监听事件方法
    scrollInit() {
      // 获取要绑定事件的元素
      const nav = document.getElementById("nav")
      // document.addEventListener('DOMMouseScroll', handler, false)
      // 添加滚轮滚动监听事件，一般是用下面的方法，上面的是火狐的写法
      nav.addEventListener('mousewheel', handler, false)
      // 滚动事件的出来函数
      function handler(event) {
        // 获取滚动方向
        const detail = event.wheelDelta || event.detail;
        // 定义滚动方向，其实也可以在赋值的时候写
        const moveForwardStep = 1;
        const moveBackStep = -1;
        // 定义滚动距离
        let step = 0;
        // 判断滚动方向,这里的100可以改，代表滚动幅度，也就是说滚动幅度是自定义的
        if (detail < 0) {
          step = moveForwardStep * 100;
        } else {
          step = moveBackStep * 100;
        }
        // 对需要滚动的元素进行滚动操作
        nav.scrollLeft += step;
      }
    },
    // 判断当前路由是否匹配当前tag
    isActive(path) {
      //详情页携带了id 所以需要去掉id再进行判断
      let str = JSON.parse(JSON.stringify(this.$route.fullPath)),
        routePath = this.getStr(str)
      let cstr = JSON.parse(JSON.stringify(path)),
        currentRoutePath = this.getStr(cstr)
      return currentRoutePath === routePath;
    },

    // 关闭标签
    closeTags(index) {
      let currentItem = this.tagsList.splice(index, 1)[0];
      let item = this.tagsList[index]
        ? this.tagsList[index]
        : this.tagsList[index - 1];
      if (item) {
        currentItem.path === this.$route.fullPath &&
          this.$router.push(item.path);
      } else {
        if (this.memberType === "card") {
          this.$router.push("/dashboard");
        }
        // else if (this.memberType === "yuanqv") {
        //   this.$router.push("/parkManagement");
        // }
      }
    },

    // 设置标签
    setTags(route) {
      let str = JSON.parse(JSON.stringify(route.fullPath)),
        routePath = this.getStr(str)

      if (this.tagsList.length > 0) {//length > 0
        let isExist = this.tagsList.find((item) => {
          let cstr = JSON.parse(JSON.stringify(item.path)),
            currentRoutePath = this.getStr(cstr)
          return currentRoutePath == routePath
        });
        if (this.tagsList.length >= 15) {
          this.tagsList.shift();
        }
        if (!isExist) {//当没有重复的页面时
          this.tagsList.push({
            title: route.meta.title,
            path: route.fullPath,
          });
        } else {//当有重复的页面时
          this.tagsList.forEach((item) => {
            let cstr = JSON.parse(JSON.stringify(item.path)),
              currentRoutePath = this.getStr(cstr)
            if (currentRoutePath == routePath) {
              item.path = route.fullPath
            }
          })

        }
      } else {
        this.tagsList.push({//length < 0
          title: route.meta.title,
          path: route.fullPath,
        });
      }

      //-console.log('this.tagsList', this.tagsList)
      bus.$emit("tags", this.tagsList);
    },

    // 关闭其他选项
    handleCloseOther() {
      let currentItem = this.tagsList.filter((item) => {
        return item.path === this.$route.fullPath;
      });
      this.tagsList = currentItem;
    },

    // 关闭所有选项
    handleCloseAll() {
      this.tagsList = [];
      if (val === "card") {
        this.$router.push("/dashboard");
      }
      // else if (val === "yuanqv") {
      //   this.$router.push("/parkManagement");
      // }
    },

    // 标签选项
    handleTags(val) {
      val === "other" ? this.handleCloseOther() : this.handleCloseAll();
    },
    getStr(string) {
      var str_before = string.split('?')[0];
      return str_before
    }
  },
  watch: {
    $route(newValue) {
      this.setTags(newValue);
    },
    getMenuType(val) {
      if (this.initHref) {
        this.tagsList = [];
        this.memberType = val;
        //-console.log(this.$route);
        if (val === "card") {
          this.$router.push("/dashboard");
        }
        // } else if (val === "yuanqv") {
        //   this.$router.push("/parkManagement");
        // }
      }
      this.initHref = true;
    }
  },
  computed: {
    getMenuType() {
      return this.$store.state.menuType;
    },
    showTags() {
      return this.tagsList && this.tagsList.length > 0;
    },
    // 主题颜色
    subjectColor() {
      return this.$store.state.tagColor
    },
  },
};
</script>

<style scoped>
.tags {
  padding-top: 10px;
  position: relative;
  height: 50px;
  overflow: hidden;
  z-index: 1;
  background: #fff;
  padding-right: 150px;
  box-shadow: 0px 2px 6px rgba(115, 127, 128, 0.12);
}

.tags>.boundary {
  width: 100%;
  overflow: hidden;
}
.tags>.boundary::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}
.tags>.boundary:hover {
  overflow-x: auto;
}

.tags ul {
  /* box-sizing: border-box; */
  /* width: 100%; */
  height: 100%;
  display: flex;
}

.tags-li {
  box-sizing: border-box;
  margin:0 5px 3px 5px;
  font-size: 12px;
  overflow: hidden;
  cursor: pointer;
  height: 30px;
  line-height: 30px;
  border: 1px solid #e9eaec;
  background: #fff;
  padding: 0 5px 0 12px;
  vertical-align: middle;
  color: #666;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  border-radius: 2px;
}

.tags-li .active .tags-li-icon {
  background-color: #F56C6C;
}

.tags-li:not(.active):hover {
  background: #f8f8f8;
}

.tags-li.active {
  color: #fff;
}

.tags-li-title {
  float: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 5px;
  color: #666;
}

.tags-li.active .tags-li-title {
  color: #fff;
}

.tags-close-box {
  position: absolute;
  right: 5px;
  top: 0;
  box-sizing: border-box;
  text-align: center;
  height: 30px;
  z-index: 10;
}

/* .tags-li.active {
  border: 1px solid #6190e8;
  background-color: #6190e8;
} */
</style>
